import { CUSTOMER_SERVICE } from 'constants/constants';
import { Image, Flex } from 'styles';
import AddCameraFail from 'assets/shield.png';
import { Button, styled as styledMUI } from '@mui/material';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchAllSubscription } from 'store/reducers/subscriptionReducer';
import { useAppDispatch } from 'store/hooks';

const Title = styled.div`
  color: #000;
  margin: 12px 0;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: left;
  width: 100%;
`;

const Info = styled.div`
  color: #484c4f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
const Heading = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

const Content = styled.div`
  color: #484c4f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
`;

const ContactInfo = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
  text-decoration-line: underline;
`;

const Gutter = styledMUI('div')(({ theme }) => ({
  height: 'calc(100vh - 100px)',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '320px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '6px'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '440px'
  }
}));

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {
    isFreeTrial,
    isUpgrade,
    showSetupComplete,
    errorMessage,
    deviceId,
    purchaseId,
    oldSubscription
  } = location.state;

  useEffect(() => {
    dispatch(fetchAllSubscription());
  }, []);

  return (
    <Gutter>
      <Flex
        direction="column"
        height="100%"
        align="center"
        justify="space-between"
      >
        <Flex direction="column" align="center">
          <Title>{`You're All Set!`}</Title>
          <Flex direction="column" justify="start">
            {isFreeTrial && !isUpgrade && (
              <Info>
                {`Your free trial has started! Dive in and explore the features
              tailored for your newborn's safety and comfort.`}
              </Info>
            )}
            {isFreeTrial && isUpgrade && (
              <Info>
                {`Your free trial upgrade has started! Dive in and explore the
              features tailored for your newborn's safety and comfort.`}
              </Info>
            )}
            {!isFreeTrial && !isUpgrade && (
              <Info>
                {`Your subscription has been activated! Dive in and explore the
              features tailored for your newborn's safety and comfort.`}
              </Info>
            )}
            {!isFreeTrial && isUpgrade && (
              <Info>
                {`Your Upgraded subscription has been activated! Dive in and explore
              the features tailored for your newborn's safety and comfort.`}
              </Info>
            )}
            {errorMessage !== '' && (
              <Flex>
                <Flex direction="column" align="baseline">
                  <Image src={AddCameraFail} width="62" height="62" />
                  <Heading>
                    Sorry! We were unable to add the device to your subscription
                    plan
                  </Heading>
                  <Content>Please contact customer service via:</Content>
                  <Flex align="center">
                    <Content>Email: </Content>
                    <ContactInfo>{CUSTOMER_SERVICE['email']}</ContactInfo>
                  </Flex>
                  <Flex align="center">
                    <Content>Phone number: </Content>
                    <ContactInfo>{CUSTOMER_SERVICE['phone']}</ContactInfo>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>

        <Flex
          justify="space-between"
          mtop="10"
          mbottom="80"
          style={{ width: '100%' }}
        >
          <Button
            color="primary"
            sx={{
              justifyContent: 'flex-start',
              outline: 'none',
              '&:focus': {
                outline: 'none'
              }
            }}
            variant="text"
            size="small"
            onClick={() => {
              navigate('/MySubscription', {
                state: {
                  isSubscriptionUpgraded: isUpgrade,
                  oldSubscriptionId: oldSubscription?.purchase_id
                }
              });
            }}
          >
            Manage Subscription
          </Button>

          <Button
            variant="contained"
            color="primary"
            sx={{
              outline: 'none',
              '&:focus': {
                outline: 'none'
              }
            }}
            style={{
              textTransform: 'none',
              borderRadius: '25px'
            }}
            onClick={() =>
              navigate(
                showSetupComplete ? '/setup-complete' : '/ActiveDevices',
                { state: { deviceId, purchaseId } }
              )
            }
          >
            Finish Setup
          </Button>
        </Flex>
      </Flex>
    </Gutter>
  );
}

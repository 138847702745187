import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { fetchUser } from 'store/reducers/userReducers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SubscriptionDetails } from 'views/SubscriptionInfo/SubscriptionDetails';
import { UserInfo } from 'views/UserInfo/UserInfo';
import NotFound from 'views/NotFound/NotFound';
import { ActiveDevices } from 'views/ActiveDevices/ActiveDevices';
import AuthorizeDevice from 'views/AuthorizeDevice/AuthorizeDevice';
import BuySubscription from 'views/BuySubscription/BuySubscription';
import { Billings } from 'views/Billings/Billings';
import Features from 'views/Features/Features';
import { LogoutUser } from 'store/store';
import SubscriptionFunctions from 'views/SubscriptionFunctions/subscriptionFunctions';
import urls from 'configs/urls';
import { ThemeProvider } from '@mui/material';
import theme from 'configs/theme';
import SetupInit from 'views/SetupInit';
import SetupComplete from 'views/SetupComplete/SetupComplete';
import SelectDevice from 'views/SelectDevice';
import WhySubscribe from 'views/WhySubscribe/WhySubscribe';
import SkippingTrial from 'views/SkippingTrial/SkippingTrial';
import CameraSelection from 'views/CameraSelection/CameraSelection';
import PaymentSuccess from 'views/PaymentSuccess/PaymentSuccess';
import SubscriptionSummary from 'views/SubscriptionSummary/SubscriptionSummary';
import PaymentOptions from 'views/SubscriptionInfo/componenets/PaymentOptions';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import PlanList from 'views/PlanList';
import AutoLogin from 'views/AutoLogin/AutoLogin';
import Bridge from 'views/Bridge';
import AccountDeletionRequest from 'views/AccountDeletion/request';
import VerifyDeleteRequest from 'views/AccountDeletion/verifyRequest';
import AuthorizeFromApp from 'views/AuthorizeFromApp';
import LoginScreen from './views/LoginScreen/LoginScreen';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

function App() {
  axios.defaults.baseURL = urls.baseUrl;
  axios.defaults.headers.common['Partner-ID'] = 'instaview';
  axios.defaults.headers.common['Client-ID'] = 'web';
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('authorize_device')) {
      return;
    }
    if (isAuthenticated) {
      dispatch(fetchUser());
    } else {
      LogoutUser();
    }
  }, []);
  return (
    <div className="App" style={{ height: '100vh' }}>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="login" Component={LoginScreen} />
            <Route path="auto-login" Component={AutoLogin} />
            <Route path="authorizeDevice" Component={AuthorizeDevice} />
            <Route path="authorizeFromApp" Component={AuthorizeFromApp} />
            <Route
              path="requestDeleteServices"
              Component={AccountDeletionRequest}
            />
            <Route
              path="verifyDeleteServices"
              Component={VerifyDeleteRequest}
            />
            <Route path="bridge" Component={Bridge} />
            <Route element={<PrivateRoute />}>
              <Route path="/" Component={ActiveDevices} />
              <Route path="/ActiveDevices" Component={ActiveDevices} />
              <Route path="/MyAccount" Component={UserInfo} />
              <Route path="/MySubscription" Component={SubscriptionDetails} />
              <Route path="/Billings" Component={Billings} />
              <Route path="/buySubscription" Component={BuySubscription} />
              <Route path="/planlist" Component={PlanList} />
              <Route path="/payment-selections" Component={PaymentOptions} />
              <Route path="/payment-success" Component={PaymentSuccess} />
              <Route path="/setup-init" Component={SetupInit} />
              <Route path="/setup-complete" Component={SetupComplete} />
              <Route path="/selectDevice" Component={SelectDevice} />
              <Route path="/promotion" element={<Features />} />
              <Route
                path="/subscription-functions"
                element={<SubscriptionFunctions />}
              />
              <Route path="/why-subscribe" element={<WhySubscribe />} />
              <Route path="/skip-trial" element={<SkippingTrial />} />
              <Route path="/camera-selection" element={<CameraSelection />} />
              <Route
                path="/subscription-summary"
                element={<SubscriptionSummary />}
              />
              <Route path="*" Component={NotFound} />
            </Route>
            <Route path="*" Component={LoginScreen} />
          </Routes>
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signInWithPopup, OAuthProvider, OAuthCredential } from 'firebase/auth';
import GoogleLogo from 'assets/google-icon.png';
import AppleLogo from 'assets/apple-icon.png';
import Email from 'assets/email.png';
import Password from 'assets/lock.png';
import { fetchUser } from 'store/reducers/userReducers';
import { auth, googleProvider, appleProvider } from 'configs/firebaseConfig';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setRequestHeaders } from 'utils/commonFunctions';
import { leftNavData } from 'components/SideBar/SideBar';
import logo from 'assets/login_logo.png';
import { Flex, Image } from 'styles';
import styled from 'styled-components';
import { Button } from '@mui/material';
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import RemoveRedEyeSharpIcon from '@mui/icons-material/RemoveRedEyeSharp';
import axios from 'axios';
import { endpoints } from 'services/api/endpoints';

const PageContainer = styled.div`
  width: ${window.innerWidth < 450 ? window.innerWidth - 40 : 370}px;
  margin: 10px;
`;
const Heading = styled.div`
  color: #494141;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.88px;
  margin: 32px 0;
`;

const Label = styled.div`
  color: #484c4f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
`;

const InputContainer = styled.div`
  position: relative;
  margin: 16px 0;
`;

const LogInInput = styled.input`
  width: 100%;
  background: #f4f5f5;
  border: none;
  padding: 12px;
  height: 48px;
  padding-left: 50px;
  border-radius: 12px;
`;

const Info = styled.div`
  color: #8c8a94;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #efefef;
  border-radius: 2px;
  margin: 32px 0;
`;

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const onSignIn = async () => {
    try {
      const payload = {
        email,
        password,
        device_type: 'web',
        vendor_id: '8139C4D8-6320-45C9-80B4-39487F873BAD-Pink'
      };
      axios
        .post(endpoints.userLoginUrl, payload)
        .then((res) => {
          console.log('login success', res);
          const token = res.data.data.parent.auth_key;
          setRequestHeaders(token);
          dispatch(fetchUser());
        })
        .catch((err) => {
          setError(true);
          console.log('login err', err);
        });
    } catch (err) {
      setError(true);
      console.error(err);
    }
  };

  const onGoogleSignIn = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const token = await res.user.getIdToken();
      setRequestHeaders(token);
      dispatch(fetchUser());
    } catch (err) {
      console.error(err);
    }
  };

  const onAppleSignIn = async () => {
    try {
      const res = await signInWithPopup(auth, appleProvider);
      const credential = OAuthProvider.credentialFromResult(
        res
      ) as OAuthCredential;
      const { accessToken } = credential;
      setRequestHeaders(`${accessToken}`);
      dispatch(fetchUser());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (location.pathname.includes('authorize_device')) {
      return;
    }
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      navigate(`${leftNavData[0]['link']}`);
    }
  }, [isAuthenticated]);

  return (
    <Flex justify="center" align="center" height="100vh" direction="column">
      <PageContainer>
        <Image src={logo} width="60" height="60" />
        <Heading>Log In</Heading>
        {false && (
          <>
            <Info>Sign in with Open accounts</Info>
            <Flex justify="space-between">
              <Button
                sx={{
                  width: '45%',
                  background: '#F4F5F5',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                variant="outlined"
                onClick={onGoogleSignIn}
              >
                <Image
                  src={GoogleLogo}
                  width="24"
                  height="24"
                  style={{ marginRight: '10px' }}
                />{' '}
                Google
              </Button>
              <Button
                sx={{
                  width: '45%',
                  background: '#F4F5F5',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                variant="outlined"
                onClick={onAppleSignIn}
              >
                <Image
                  src={AppleLogo}
                  width="24"
                  height="24"
                  style={{ marginRight: '10px' }}
                />{' '}
                Apple ID
              </Button>
            </Flex>
            <Divider />
            <Info>Or continue with email address</Info>
          </>
        )}
        <InputContainer>
          <Label>Email</Label>
          <LogInInput
            type="text"
            placeholder="Your email"
            id="email"
            value={email}
            onChange={(e) => {
              setError(false);
              setEmail(e.target.value);
            }}
          />
          <Image
            src={Email}
            width="24"
            height="24"
            style={{
              position: 'absolute',
              left: 12,
              top: 47
            }}
          />
        </InputContainer>
        <InputContainer>
          <Label>Password</Label>
          <LogInInput
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            value={password}
            onChange={(e) => {
              setError(false);
              setPassword(e.target.value);
            }}
          />
          <Image
            src={Password}
            width="24"
            height="24"
            style={{
              position: 'absolute',
              left: 12,
              top: 47
            }}
          />
          <Flex
            style={{
              position: 'absolute',
              right: 15,
              top: 47,
              cursor: 'pointer'
            }}
            onClick={() => setShowPassword((prevState) => !prevState)}
          >
            {showPassword ? (
              <RemoveRedEyeSharpIcon />
            ) : (
              <VisibilityOffSharpIcon />
            )}
          </Flex>
          {error ? (
            <div style={{ color: 'red' }}> email or password is incorrect!</div>
          ) : (
            ''
          )}
        </InputContainer>

        <Button
          sx={{
            width: '100%'
          }}
          variant="contained"
          disabled={!(email && password)}
          onClick={onSignIn}
        >
          Sign In
        </Button>
      </PageContainer>
    </Flex>
  );
}

export default LoginScreen;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Flex, Image } from 'styles';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import logo from 'assets/login_logo.png';
import { endpoints } from 'services/api/endpoints';
import { setRequestHeaders } from 'utils/commonFunctions';
import { GetUserDetails } from 'services/api/UserApi';

const PageContainer = styled.div`
  width: ${window.innerWidth < 450 ? window.innerWidth - 40 : 370}px;
  margin: 10px;
`;

const ContentContainer = styled.div`
  font-size: 30px;
  margin: 80px 20px;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 22px;
`;

const Heading = styled.div`
  color: #494141;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.88px;
  margin: 32px 0;
`;

const Info = styled.div`
  color: #8c8a94;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
`;

type StatusMessages = {
  [key: string]: string;
};

const statusMessage: StatusMessages = {
  authorizing: 'Authorizing Request...',
  token_expired: 'Link is expired or invalid. Please generate link again.'
};

function VerifyDeleteRequest() {
  const [status, setStatus] = useState<string>('authorizing');
  const location = useLocation();
  const [customTokenVerified, setCustomTokenVerified] = useState(false);

  const query = new URLSearchParams(location.search);
  const token = query.get('token') || '';

  const deleteUserAccount = (email: any) => {
    const payload = {
      email
    };
    axios
      .delete(endpoints.deleteUser, { data: payload })
      .then((res) => {
        if (res.status === 200) {
          setStatus('');
          setCustomTokenVerified(true);
        }
      })
      .catch((err) => {
        setStatus('token_expired');
        console.log('deleteUserAccount', err);
      });
  };

  const getUser = async () => {
    const res = await GetUserDetails();

    console.log(res);
    deleteUserAccount(res.data.user_account.email);
  };

  const verifyToken = (one_time_token: string) => {
    axios
      .post(`/oauth2/one-time-token/login?token=${one_time_token}`)
      .then((res) => {
        console.log('resp ', res);
        setRequestHeaders(res.data.data.token.access_token);

        getUser();
      })
      .catch((err) => {
        setStatus('token_expired');
        console.log('fetchAccessToken', err);
      });
  };

  useEffect(() => {
    if (token === '') {
      setStatus('token_expired');
      return;
    }
    if (!customTokenVerified) {
      verifyToken(token);
    }
  }, [token]);

  return (
    <Flex direction="column" align="center" height="100vh" mtop="30">
      <PageContainer>
        <ContentContainer>
          {!customTokenVerified && status === 'authorizing' && (
            <CircularProgress size={30} />
          )}
          {!customTokenVerified && statusMessage[status]}
        </ContentContainer>
        {customTokenVerified && (
          <>
            <Image src={logo} width="60" height="60" />
            <Heading>Verify Account Deletion</Heading>
            <Info style={{ marginTop: '42px' }}>
              Your request to delete your InstaBaby account is verified. Our
              team will review and process your request.
            </Info>
            <Info>{`We're sorry to see you go :(`}</Info>
          </>
        )}
      </PageContainer>
    </Flex>
  );
}
export default VerifyDeleteRequest;

import { CUSTOMER_SERVICE } from 'constants/constants';
import { Flex } from 'styles';
import styled from 'styled-components';

const CardHeading = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

const HeadingIcon = styled.div`
  width: 16px;
  height: 32px;
  border-radius: 4px;
  background: #d7eea1;
  margin-right: 16px;
`;

const Content = styled.div`
  color: #484c4f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
`;

const Info = styled.div`
color: #000);
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
letter-spacing: -0.16px;
text-decoration-line: underline;
`;

export function CustomerServiceContent() {
  return (
    <>
      <Flex justify="space-between" pbottom="24">
        <Flex>
          <HeadingIcon />
          <CardHeading>Cancel Subscription</CardHeading>
        </Flex>
      </Flex>
      <Content>
        Please contact customer service to cancel your subscription via:
      </Content>
      <Flex align="center">
        <Content>Email: </Content>
        <Info>{CUSTOMER_SERVICE['email']}</Info>
      </Flex>

      <Flex align="center">
        <Content>Phone number: </Content>
        <Info>{CUSTOMER_SERVICE['phone']}</Info>
      </Flex>
    </>
  );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import SideBar from 'components/SideBar/SideBar';
import TopBar from 'components/TopBar/TopBar';
import { IsMobile } from 'hooks/isMobile';
import { useState } from 'react';

function PrivateRoute() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isAuthenticated } = useSelector((state: any) => state.user);
  const isMobile = IsMobile();
  const [openSideBar, setOpenSideBar] = useState(!isMobile);
  if (!isAuthenticated) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />;
  }

  // authorized so return child components
  return (
    <PageContainer>
      {!isMobile && (
        <SideBar setOpenSideBar={setOpenSideBar} openSideBar={openSideBar} />
      )}

      <Container>
        <TopBar setOpenSideBar={setOpenSideBar} openSideBar={openSideBar} />
        {isMobile && (
          <SideBar setOpenSideBar={setOpenSideBar} openSideBar={openSideBar} />
        )}
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </Container>
    </PageContainer>
  );
}

export default PrivateRoute;

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ContentContainer = styled.div`
  padding: 20px 20px;
  margin-top: 72px;
  display: block;
  flex: 1;
  background: ${IsMobile() ? '#ffff' : '#f4f5f7'};
`;

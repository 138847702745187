import { CURRENCY } from 'constants/constants';
import { Button, styled as styledMUI } from '@mui/material';
import dayjs from 'dayjs';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import { Flex } from 'styles';
import { ReactComponent as DollarCircle } from 'assets/dollar-circle.svg';
import { ReactComponent as CardCoin } from 'assets/card-coin.svg';
import { ReactComponent as Card } from 'assets/card.svg';
import { ReactComponent as VideCam } from 'assets/video-cam.svg';

const Container = styledMUI('div')(({ theme }) => ({
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '320px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '6px'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '440px'
  }
}));

const Title = styled.div`
  color: #000;
  text-align: center;
  margin-top: 14px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const Heading = styled.div`
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
  margin: 32px 0 12px;
`;

const Info = styled.div`
  color: #484c4f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const PlanSummary = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
`;

const CameraBox = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 20px;
  justify-content: space-between;
  align-items: center;
  background: #f0f4f4;
  border-radius: 16px;
`;
const CameraName = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #5fa4fb;
`;
const PlanName = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #000;
`;

const SummaryText = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
`;

export default function SetupComplete() {
  const navigate = useNavigate();
  const userDetails = useAppSelector((state) => state.user.userDetails);
  const subscriptionDetails = useAppSelector(
    (state) => state.subscription.subscriptionData
  );

  const location = useLocation();
  const { deviceId, purchaseId } = location.state;

  const babyName =
    userDetails?.primary_devices.find((item) => item.device_id === deviceId)
      ?.infant_name || 'Your baby';

  const subscriptionData = subscriptionDetails.filter(
    (item) => item.purchase_id === purchaseId
  )[0];

  const {
    card_details,
    currency,
    next_bill_amount,
    next_billing_date,
    subscription_cycle,
    sku_name
  } = subscriptionData;

  return (
    <Container>
      <Flex
        direction="column"
        // height="100%"
        align="start"
        // justify="space-between"
        gap="24px"
      >
        <Flex direction="column" align="center">
          <Title>Success</Title>
          <Heading>
            Congratulations! {babyName} is now covered under the Sleep Insights
            Plan!
          </Heading>
        </Flex>

        <Flex direction="column" align="start" gap="16px">
          <PlanSummary>Plan summary</PlanSummary>
          <Flex gap="8px">
            <DollarCircle />
            <SummaryText>
              Next Payment {CURRENCY[currency]}
              {next_bill_amount ? next_bill_amount / 100 : ''} On{' '}
              {dayjs(next_billing_date || '').format('MMM D,YYYY')}
            </SummaryText>
          </Flex>
          <Flex gap="8px">
            <CardCoin />
            <SummaryText>
              Billed {subscription_cycle === 1 ? 'Monthly' : 'Yearly'}
            </SummaryText>
          </Flex>
          <Flex gap="8px">
            <Card />
            <SummaryText>
              Charged to {card_details?.brand} Ending in {card_details?.last4}
            </SummaryText>
          </Flex>
        </Flex>

        <CameraBox>
          <Flex direction="column" align="start" gap="8px">
            <CameraName>{`${babyName}'s Cam`}</CameraName>
            <PlanName>{`${sku_name} Plan`}</PlanName>
          </Flex>
          <VideCam />
        </CameraBox>

        {!(isMobile && (isIOS || isAndroid)) && (
          <Info>
            Your new camera is setup & ready to use. Open your app to view your
            camera
          </Info>
        )}
        <Flex justify="flex-end" style={{ width: '100%' }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              outline: 'none',
              '&:focus': {
                outline: 'none'
              }
            }}
            style={{
              width: '50%',
              maxWidth: '300px',
              borderRadius: '44px'
            }}
            href={
              isMobile && (isIOS || isAndroid)
                ? 'launchinstababyapp://instababy'
                : '#'
            }
            onClick={(e) => {
              if (!(isMobile && (isIOS || isAndroid))) {
                e.preventDefault();
                navigate('/ActiveDevices');
              }
            }}
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    </Container>
  );
}

import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import axios from 'axios';
import styled from 'styled-components';
import dayjs from 'dayjs';
import CameracIcon from 'assets/camera.svg';
import WifiIcon from 'assets/wifi.svg';
import LocationIcon from 'assets/location.svg';
import FirmWareIcon from 'assets/info.svg';
import LastSeenIcon from 'assets/clock.svg';
import CameraSnapshot from 'assets/camera-view.png';
import CameraFrameTopNudge from 'assets/camera-frame-top-nudge.png';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IsMobile } from 'hooks/isMobile';
import Spinner from 'components/Spinner';
import { useNavigate } from 'react-router-dom';
import {
  GetSubscriptionDetails,
  SubscriptionType,
  CheckFreeTrialSubscription
} from 'services/api/subscriptionApi';
import { endpoints } from 'services/api/endpoints';

export interface DeviceInfo {
  device_id: string;
  password: string;
  infant_name: string;
  image_url: string;
  sku_id: string;
  is_paired: boolean;
  is_activated: boolean;
  firmware_version: [string];
  time_zone: string;
  wifi_ssid: [string];
  model_name: string;

  device_state: {
    battery_percentage: number;
    last_seen: string;
    status: string;
    firmware_version: string;
    wifi_name: string;
    ip_addr: string;
  };
  location: string;
  manufacturer: {
    model: string;
    name: string;
    year: number;
  };
  existingSubscription?: {
    sku_name: string;
  };
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${IsMobile() ? '0px' : '20px'};
  background-color: ${IsMobile() ? '#fff' : '#ffff'};
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: ${IsMobile()
    ? 'none'
    : `
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    `};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FlexContainer = styled.div<{ bottom?: string }>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.bottom || 15}px;
`;

const Label = styled.div`
  color: #8c8a94;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-left: 12px;
`;

const Value = styled.div`
  color: #494141;
  font-weight: 700;
  margin-left: 5px;
`;

const Image = styled.img<{
  width?: string;
  height?: string;
  borderRadius?: string;
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.borderRadius || 0}px;
`;

const DeviceWrapper = styled.div`
  margin-top: 20px;
  margin-right: 12px;
`;

const TopNudge = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
`;
const Title = styled.div`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
const SubTitle = styled.div`
  color: #484c4f;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 12px;
  margin-top: 4px;
`;
const OfferContainer = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #d7eea1;
  position: absolute;
  bottom: 0px;
  width: calc(100% - 16px);
  margin: 8px;
  cursor: pointer;
`;

const ExclusiveOffer = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background: rgba(255, 255, 255);
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const ImageContainer = styled.div`
  position: relative;
  margin-top: 22px;
`;

const Heading2 = styled.div`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;

const ClickTypography = styled.span`
  color: #5fa4fb;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
`;

const AdditionText = styled.span`
  color: #484c4f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-left: 5px;
`;

export function ActiveDevices() {
  const [devicesList, setDevicesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEligibleForTrial, setIsEligibleForTrial] = useState(false);
  const subscriptionData = useAppSelector<SubscriptionType[]>(
    (state) => state.subscription.subscriptionData
  );

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      GetSubscriptionDetails(),
      axios.get(endpoints.listDevicesUrl),
      CheckFreeTrialSubscription()
    ])
      .then((res) => {
        const [allSubscriptionsData, allDevicesData, freeTrialEligibility] =
          res;
        console.log('allSubscriptionsData', allSubscriptionsData.data);
        // debugger;

        const findLinkedSubscriptionByDeviceId = (deviceId: string) => {
          // debugger;
          console.log('deviceId - ', deviceId);

          return allSubscriptionsData.data.subscriptions.find((sub) => {
            console.log('sub - ', sub);

            // debugger;
            return sub.devices.find((item) => item.id === deviceId);
          });
        };

        setIsEligibleForTrial(freeTrialEligibility.data.trial);

        const devices = allDevicesData.data.data.devices.map(
          (deviceItem: DeviceInfo) => {
            console.log('deviceItem - ', deviceItem);
            const copy = deviceItem;
            // debugger;
            const found = findLinkedSubscriptionByDeviceId(
              deviceItem.device_id
            );
            console.log('found - ', found);

            // debugger;
            copy.existingSubscription = found;
            console.log('deviceItem - ', copy);

            return copy;
          }
        );
        // debugger;

        console.log('devices - ', devices);
        // debugger;
        setDevicesList(devices);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Spinner />;
  }
  console.log('devicesList', devicesList);
  // debugger;

  return (
    <>
      {devicesList.length > 0 && (
        <Card>
          <FlexWrapper>
            {devicesList.map((el: DeviceInfo) => {
              const { last_seen } = el?.device_state || {};
              const nudgeForTrial = isEligibleForTrial;
              console.log('el', el);

              return (
                <DeviceWrapper key={el?.device_id}>
                  <Heading2>{el?.infant_name || ' Camera Name'}</Heading2>
                  <Box sx={{ m: (theme) => theme.spacing(1.25, 0) }}>
                    <ClickTypography
                      onClick={() => navigate('/MySubscription')}
                    >
                      Click here{' '}
                    </ClickTypography>
                    <AdditionText>
                      to learn more about your subscription
                    </AdditionText>
                  </Box>
                  <ImageContainer>
                    <Image
                      src={CameraSnapshot}
                      borderRadius="15"
                      width={IsMobile() ? `${window.innerWidth - 40}` : '335'}
                    />
                    {nudgeForTrial && !el?.existingSubscription && (
                      <OfferContainer
                        onClick={() => {
                          const url = `/planlist?deviceId=${el.device_id}&pairingFlow=false`;

                          navigate(url);
                        }}
                      >
                        <Title>Exclusive Offer</Title>
                        <SubTitle>Try 15 days Free-Trial</SubTitle>
                        <ExclusiveOffer>Add single camera plan</ExclusiveOffer>
                      </OfferContainer>
                    )}
                    {!nudgeForTrial && !el?.existingSubscription && (
                      <>
                        <TopNudge>
                          <div
                            style={{
                              padding: '6px',
                              borderRadius: '24px',
                              background: '#fff',
                              marginTop: '-18px'
                            }}
                          >
                            <Image
                              src={CameraFrameTopNudge}
                              width="112"
                              height="24"
                            />
                          </div>
                        </TopNudge>
                        <OfferContainer
                          onClick={() => {
                            const url = `/planlist?deviceId=${el.device_id}&pairingFlow=false`;

                            navigate(url);
                          }}
                        >
                          <Title>Get more out of your camera.</Title>
                          <SubTitle>
                            Review, save, and share videos from your camera.
                          </SubTitle>
                          <ExclusiveOffer>
                            Add single camera plan
                          </ExclusiveOffer>
                        </OfferContainer>
                      </>
                    )}
                  </ImageContainer>
                  {/* <Image src={CameraSnapshot} borderRadius="15" /> */}

                  <Accordion
                    sx={{
                      boxShadow: 'none',
                      border: 'none',
                      margin: 0,
                      '&::before': {
                        display: 'none'
                      }
                    }}
                  >
                    <AccordionSummary
                      aria-expanded="true"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        padding: 0,
                        minHeight: 48,
                        '& .Mui-expanded': {
                          margin: 0
                        },
                        '& .MuiAccordionSummary-root': {
                          padding: 0
                        }
                      }}
                    >
                      <Typography>See more </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: 0,
                        '& .Mui-expanded': {
                          margin: 0
                        },
                        '& .MuiAccordionDetails-root': {
                          padding: 0
                        }
                      }}
                    >
                      <FlexContainer>
                        <Image src={WifiIcon} width="24" height="24" />
                        <Label>WiFi - </Label>
                        <Value>{el?.wifi_ssid?.join('')}</Value>
                      </FlexContainer>
                      <FlexContainer>
                        <Image src={FirmWareIcon} width="24" height="24" />
                        <Label>Firmware version - </Label>
                        <Value>{el?.firmware_version?.join()}</Value>
                      </FlexContainer>
                      <FlexContainer>
                        <Image src={LocationIcon} width="24" height="24" />
                        <Label>Location - </Label>
                        <Value>{el?.time_zone}</Value>
                      </FlexContainer>
                      <FlexContainer>
                        <Image src={CameracIcon} width="24" height="24" />
                        <Label>Model - </Label>
                        <Value>{el?.model_name}</Value>
                      </FlexContainer>
                      <FlexContainer>
                        <Image src={LastSeenIcon} width="24" height="24" />
                        <Label>Last active time - </Label>
                        <Value>
                          {last_seen
                            ? dayjs(last_seen).format('DD.MM.YY - HH:mm A')
                            : ''}
                        </Value>
                      </FlexContainer>
                      <FlexContainer>
                        <Image src={LastSeenIcon} width="24" height="24" />
                        <Label>Subscription plan - </Label>
                        <Value>
                          {el?.existingSubscription?.sku_name || 'NA'}
                        </Value>
                      </FlexContainer>
                    </AccordionDetails>
                  </Accordion>
                </DeviceWrapper>
              );
            })}
          </FlexWrapper>
        </Card>
      )}
      {subscriptionData.length > 0 && devicesList.length === 0 && (
        <Card>
          <DeviceWrapper>
            <Heading2>
              No camera is part of your subscription. Please finish pairing the
              camera from app and complete the subscription.
            </Heading2>
          </DeviceWrapper>
        </Card>
      )}
    </>
  );
}
